import { db, serverTimestamp } from '../../../../plugins/firebase'

const getters = {
  entities_people: ({ entities }) => {
    return entities.filter((entity) => {
      return entity.type === 'person'
    })
  },
  entity_person:
    ({ entities }, getters, rootState, rootGetters) =>
    (id = null) => {
      let value = {
        address: '',
        name: '',
        type: 'person',
        dob: {
          day: '',
          month: '',
          year: '',
        },
      }
      if (id) {
        const people = entities.filter((entity) => {
          return entity.type === 'person' && entity.id === id
        })

        // these will have their dob validated >=18yo in DateField.vue
        const entitiesRequiredToBeAdult = [
          ...rootGetters['account/poa_vic_mtdm'].decisionMakers,
        ]
        const isAdult = entitiesRequiredToBeAdult.some((e) => e === id)
        value = { ...people[0], isAdult } || value
      }

      return value
    },
}

const actions = {
  validate_entity_person: async ({ getters, rootGetters }, { name }) => {
    const removeWhiteSpaces = (str) => {
      return str.toLowerCase().replace(/^\s+|\s+$|\s+(?=\s)/g, '')
    }

    if (
      removeWhiteSpaces(rootGetters['account/user_name'].displayFull) ===
      removeWhiteSpaces(name)
    ) {
      return {
        type: 'SameAsProfileError',
        message:
          'You can not add yourself as a contact in your own account or Will.',
      }
    }

    const existing = getters.entities_people.find(
      (x) => removeWhiteSpaces(x.name) === removeWhiteSpaces(name)
    )
    if (existing) {
      return {
        type: 'ExistingPersonError',
        message: existing.name
          ? `A contact with the name ${existing.name} already exists.`
          : 'A contact with this name already exists.', // shouldn't happen
        person: existing,
      }
    }
    return null
  },
  save_entity_person: async ({ getters }, { id, person }) => {
    const { uid } = getters.auth_user
    const personId =
      id || db.collection('usersV2').doc(uid).collection('entities').doc().id
    const docRef = db
      .collection('usersV2')
      .doc(uid)
      .collection('entities')
      .doc(personId)

    const data = {
      ...person,
      type: 'person',
    }

    if (!id) {
      data.createdAt = serverTimestamp
    }

    await docRef.set(data, { merge: true })

    return personId
  },
  validate_entity_person_for_delete: async ({ rootGetters }, id) => {
    const giftRecipients = rootGetters['account/entities_gifts'].map(
      (x) => x.recipient
    )
    const petGuardians = rootGetters['account/entities_pets'].map(
      (x) => x.guardian
    )

    const messageRecipients = rootGetters['account/entities_messages'].map(
      (x) => x.recipient
    )

    const executors = rootGetters['account/will_executors'].value
    const primaryExecutors = executors.primary.filter(
      (x) => typeof x === 'string'
    )
    const backupExecutors = executors.backup.filter(
      (x) => typeof x === 'string'
    )

    const partnerId = rootGetters['account/user_partner'].value.partnerId

    const childGuardians = rootGetters['account/entities_people'].flatMap(
      (x) => x.guardians
    )

    const estate = rootGetters['account/will_estate'].value
    let beneficiaries = Object.keys(estate)
    beneficiaries.forEach((id) => {
      if (
        estate[id].backupOption === 'newBeneficiaries' &&
        estate[id].backupEntities
      ) {
        beneficiaries = [...beneficiaries, ...estate[id].backupEntities]
      }
    })

    const allUsedPeople = [
      ...giftRecipients,
      ...petGuardians,
      ...messageRecipients,
      ...primaryExecutors,
      ...backupExecutors,
      ...childGuardians,
      ...[partnerId],
      ...beneficiaries,
    ]

    return !allUsedPeople.includes(id)
  },
  validate_entity_person_for_delete_poa: async ({ rootGetters }, id) => {
    const poaUsedEntities = []
    switch (rootGetters['account/user_address'].value.state) {
      case 'act':
        poaUsedEntities.push(
          ...rootGetters['account/poa_act_epa'].attorneys,
          rootGetters['account/poa_act_epa'].marriage,
          ...rootGetters['account/poa_act_epa'].substitutes
        )
        break
      case 'nsw':
        poaUsedEntities.push(
          ...rootGetters['account/poa_nsw_eg'].guardians,
          rootGetters['account/poa_nsw_eg'].substitute,
          ...rootGetters['account/poa_nsw_epa'].attorneys,
          ...rootGetters['account/poa_nsw_epa'].substitutes,
          ...rootGetters['account/poa_nsw_epa'].authorisations.benefitsPeople
        )
        break
      case 'nt':
        poaUsedEntities.push(
          ...rootGetters['account/poa_nt_app'].decisionMakers.map(
            (d) => d.entity
          )
        )
        break
      case 'qld':
        poaUsedEntities.push(
          ...rootGetters['account/poa_qld_epa'].attorneys,
          ...rootGetters['account/poa_qld_epa'].financialNotify.other,
          ...rootGetters['account/poa_qld_epa'].personalNotify.other
        )
        break
      case 'sa':
        poaUsedEntities.push(
          ...rootGetters['account/poa_sa_epa'].donees,
          ...rootGetters['account/poa_sa_acd'].substitutes
        )
        break
      case 'tas':
        poaUsedEntities.push(
          ...rootGetters['account/poa_tas_eg'].guardians,
          rootGetters['account/poa_tas_eg'].substitute,
          ...rootGetters['account/poa_tas_epa'].attorneys
        )
        break
      case 'vic':
        poaUsedEntities.push(
          ...rootGetters['account/poa_vic_epa'].attorneys.map((a) => a.entity),
          ...rootGetters['account/poa_vic_epa'].attorneys.flatMap(
            (a) => a.alternatives
          ),
          ...rootGetters['account/poa_vic_mtdm'].decisionMakers
        )
        break
      case 'wa':
        poaUsedEntities.push(
          ...rootGetters['account/poa_wa_epa'].attorneys,
          ...rootGetters['account/poa_wa_epa'].substituteAttorneys.map(
            (s) => s.entity
          ),
          ...rootGetters['account/poa_wa_epg'].guardians,
          ...rootGetters['account/poa_wa_epg'].substituteGuardians.map(
            (s) => s.entity
          )
        )
        break
      default:
        break
    }
    return !poaUsedEntities.includes(id)
  },
}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
