import { chain, includes, isEmpty, toLower } from 'lodash'
import Vue from 'vue'

import { db } from '@/plugins/firebase'

const getters = {
  charities_tags: (state, getters) => {
    let charities = getters['charities_list_featured']()

    return chain(charities)
      .filter((x) => x.tags)
      .flatMap((x) => x.tags)
      .reduce((a, b) => a.concat(b), [])
      .uniq()
      .sort()
      .value()
  },
  all_charities: (state) => state.charities,
  charities_list_featured:
    (state) =>
    (list = 'default', query = '', tag = null) => {
      if (!state.charities) {
        return null
      }

      return chain(state.charities)
        .filter((o) => {
          return (
            !isEmpty(o.name) &&
            o.abn &&
            o.logo &&
            !isEmpty(o.logo.default) &&
            o.settings?.featuredCharity &&
            typeof o.settings.featuredCharity.default === 'number' &&
            includes(toLower(o.name), toLower(query)) &&
            (isEmpty(tag) || (o.tags && o.tags.includes(tag)))
          )
        })
        .orderBy(
          [
            (x) =>
              x.settings &&
              x.settings.featuredCharity &&
              x.settings.featuredCharity[list],
            (x) =>
              x.settings &&
              x.settings.featuredCharity &&
              x.settings.featuredCharity['default'],
          ],
          ['asc']
        )
        .value()
    },
}

const actions = {
  async load_charities({ state, commit }) {
    if (state.charities === null) {
      const snap = await db
        .collection('referrers')
        .where('type', '==', 'charity')
        .get()
      const result = []
      snap.docs.forEach((doc) => {
        const data = doc.data()
        result.push({ id: doc.id, ...data })
      })
      commit('SET_CHARITIES', result)
    }
    return true
  },
}

const mutations = {
  SET_CHARITIES(state, charities) {
    Vue.set(state, 'charities', charities)
  },
}

export default {
  getters,
  actions,
  mutations,
}
