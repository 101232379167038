import VuexReset from '@ianwalter/vuex-reset'
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { firestoreAction, vuexfireMutations } from 'vuexfire'

import { db, serverTimestamp } from '@/plugins/firebase'

import account from './account/index'
import anon from './anon/index'
import app from './app/index'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    VuexReset(),
    createPersistedState({ paths: ['referrer'] }),
    createPersistedState({
      paths: ['anon'],
      key: 'wd_anon',
    }),
  ],
  state: {
    purchase: null,
    registrationData: {},
    mailchimp: {},
    referrer: {},
  },
  mutations: {
    ...vuexfireMutations,
    reset: () => {},
    cacheReferrer(state, referrer) {
      Vue.set(state, 'referrer', referrer)
    },
  },
  actions: {
    bindAccountData: firestoreAction(async ({ bindFirestoreRef }, uid) => {
      // return the promise returned by `bindFirestoreRef`
      return await bindFirestoreRef('account.data', db.doc('usersV2/' + uid))
    }),
    bindAccountEntities: firestoreAction(async ({ bindFirestoreRef }, uid) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        'account.entities',
        db
          .doc('usersV2/' + uid)
          .collection('entities')
          .orderBy('createdAt')
      )
    }),
    bindAccountVault: firestoreAction(async ({ bindFirestoreRef }, uid) => {
      // return the promise returned by `bindFirestoreRef`
      return bindFirestoreRef(
        'account.vault',
        db.doc('usersV2/' + uid).collection('vault')
      )
    }),
    bindPartnerPurchase: firestoreAction(
      async ({ bindFirestoreRef }, token) => {
        // return the promise returned by `bindFirestoreRef`
        return bindFirestoreRef(
          'app.partnerPurchase',
          db.doc('partners/' + token)
        )
      }
    ),
    unbindPartnerPurchase: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('app.partnerPurchase')
    }),
    unbindData: firestoreAction(({ unbindFirestoreRef }) => {
      unbindFirestoreRef('account.data')
      unbindFirestoreRef('account.entities')
      unbindFirestoreRef('account.vault')
      unbindFirestoreRef('app.partnerPurchase')
    }),
    async set_anon_user({ commit, dispatch }) {
      dispatch('unbindData')
      return await commit('reset')
    },
    async bind_data({ dispatch, commit }, { auth }) {
      commit('account/SET_AUTH', auth)
      await dispatch('app/load_app_settings')
      await dispatch('app/load_pricing')
      await dispatch('bindAccountData', auth.uid)
      await dispatch('bindAccountEntities', auth.uid)
      await dispatch('bindAccountVault', auth.uid)
      await dispatch('app/load_user_referrer')
      commit('app/SET_DATA_LOADED', true)

      return true
    },
    // TODO: replace set_purchase
    async set_purchase({ state }, purchase) {
      let sale = {
        uid: state.user.uid,
        createdAt: serverTimestamp,
        ...(typeof purchase.price !== 'undefined' && { price: purchase.price }),
        ...(typeof purchase.promocode !== 'undefined' && {
          promocode: purchase.promocode,
        }),
        ...(typeof purchase.prepaid !== 'undefined' && {
          prepaid: purchase.prepaid,
        }),
        ...(state.will.meta &&
          state.will.meta.referrer &&
          state.will.meta.referrer.id && {
            referrer: state.will.meta.referrer.id,
          }),
      }

      state.purchase = purchase
      return await db.collection('eventSale').add(sale)
    },
  },
  getters: {
    // TODO: replace person_roles
    person_roles: (state, getters) => (id) => {
      let roles = []

      if (Object.keys(state.will.beneficiaries).includes(id)) {
        roles.push('beneficiary')
      }

      if (state.will.executors.primary.includes(id)) {
        roles.push('primaryExec')
      }

      if (state.will.executors.backup.includes(id)) {
        roles.push('backupExec')
      }

      if (state.will.partnerId === id) {
        roles.push('partner')
      }

      if (getters.is_guardian(id)) {
        roles.push('guardian')
      }

      if (getters.is_guardian_pet(id)) {
        roles.push('pet')
      }

      if (getters.is_gift_recipient(id)) {
        roles.push('gift')
      }

      return roles
    },
  },
  modules: {
    anon,
    app,
    account,
  },
})
