const getters = {
  funeral_memorialisation: ({ data }, getters) => {
    const wishes = data?.wishes || {}
    const value = {
      remains: wishes.remains || null, // null is default for multiple choice
      container: wishes.container || '', // empty string default for free text
      location: wishes.location || '',
    }

    const selectedOption = value.remains
      ? getters.lists_group_option('remains', value.remains)
      : null
    if (!selectedOption) {
      value.remains = null
    }

    return {
      value: value,
      isComplete: selectedOption?.active || false,
    }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
