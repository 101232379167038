import { db, fb } from '../../../plugins/firebase'
import assets from './vault/assets'
import liabilities from './vault/liabilities'
import wishes from './vault/wishes'

const getters = {
  ...wishes.getters,
  ...assets.getters,
  ...liabilities.getters,
  vault: (state) => (id) => {
    return (
      state.vault.filter((key) => {
        return key.id === id
      })[0] || {}
    )
  },
}

const actions = {
  ...wishes.actions,
  ...assets.actions,
  ...liabilities.actions,
  async save_vault({ getters }, { id, data }) {
    const { uid } = getters.auth_user
    const docRef = db.collection('usersV2').doc(uid).collection('vault').doc(id)

    Object.entries(data).forEach(([key, value]) => {
      if (value === undefined) {
        value = fb.firestore.FieldValue.delete()
        return
      }

      if (typeof value === 'object' && typeof key === 'string') {
        Object.keys(value).forEach((k) => {
          value[k] =
            value[k] === undefined ? fb.firestore.FieldValue.delete() : value[k]
        })
      }
    })

    return await docRef.set(data, { merge: true })
  },
}

const mutations = {
  ...wishes.mutations,
  ...assets.mutations,
  ...liabilities.mutations,
}

export default {
  getters,
  actions,
  mutations,
}
