<template>
  <svg
    height="100%"
    class="add-icon"
    viewBox="0 0 26 26"
  >
    <circle
      cx="13"
      cy="13"
      r="13"
    />
    <path
      d="M12.9703 18.4385L12.9703 6.79485"
      stroke="white"
      stroke-width="2"
    />
    <path
      d="M18.7925 12.6166L7.14886 12.6166"
      stroke="white"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'DoneIcon',
}
</script>

<style lang="scss">
.add-icon {
  width: auto;
  circle {
    fill: currentColor;
  }
}
</style>
