const state = {
  remains: [
    {
      key: 'burial',
      active: true,
      label: 'Burial',
    },
    {
      key: 'cremated',
      active: true,
      label: 'Cremation',
    },
    {
      key: 'science',
      active: true,
      label: 'Donate to science',
    },
    {
      key: 'family',
      active: false,
      label: 'Let family decide',
    },
  ],
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
