const state = {
  giftTypes: [
    {
      label: 'Money',
      key: 'money',
      text: 'Leave a set dollar amount (eg. $1,000)',
    },
    {
      label: 'Asset or Personal Item',
      key: 'possession',
      text: 'Leave an asset or item (eg. property, vehicle, furniture, jewellery)',
    },
  ],
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
