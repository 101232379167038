import assetTypes from './lists/assetTypes'
import auStates from './lists/auStates'
import backupBeneficiaries from './lists/backupBeneficiaries'
import faqs from './lists/faqs'
import funeral from './lists/funeral'
import giftTypes from './lists/giftTypes'
import liabilityTypes from './lists/liabilityTypes'
import months from './lists/months'
import professionalExecutors from './lists/professionalExecutors'
import relationshipStatus from './lists/relationshipStatus'
import remains from './lists/remains'
import willSteps from './lists/willSteps'

const state = {
  ...months.state,
  ...giftTypes.state,
  ...remains.state,
  ...funeral.state,
  ...auStates.state,
  ...assetTypes.state,
  ...liabilityTypes.state,
  ...professionalExecutors.state,
  ...faqs.state,
  ...relationshipStatus.state,
  ...backupBeneficiaries.state,
  ...willSteps.state,
  subscriptions: [
    {
      key: 'plan_01',
      price: 14,
    },
  ],
}

const getters = {
  list_group:
    ({ lists }) =>
    (group) => {
      return lists?.[group] || []
    },
  lists_group_option:
    ({ lists }) =>
    (group, key) => {
      const options = lists?.[group] || []

      return (
        options.filter((option) => {
          return option.key === key
        })[0] || null
      )
    },
}

export default {
  state,
  getters,
}
