import estate from './will/estate'
import executors from './will/executors'
import progress from './will/progress'
import wishes from './will/wishes'

const getters = {
  ...estate.getters,
  ...executors.getters,
  ...wishes.getters,
  ...progress.getters,
}

const actions = {
  ...estate.actions,
  ...executors.actions,
  ...wishes.actions,
  ...progress.actions,
}

const mutations = {
  ...estate.mutations,
  ...executors.mutations,
  ...wishes.mutations,
  ...progress.mutations,
}

export default {
  getters,
  actions,
  mutations,
}
