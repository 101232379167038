import { wa_epa_required } from '../../../../config/poaRequiredFields'
import store from '../../../index'
import entityArrayComplete from './utils'

const getters = {
  wa_epa_is_valid: ({ vault }) => {
    const doc = vault.find((o) => o.id === 'poaWaEpa') || {}
    const people = store.getters['account/entities_people']
    // if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
    if (!doc.attorneys?.length || !doc.commencement) return false
    if (!entityArrayComplete(doc.attorneys, people, wa_epa_required))
      return false
    return true
  },
  poa_wa_epa: ({ vault }) => {
    const doc = vault.find((o) => o.id === 'poaWaEpa') || {}

    const options = {
      appointmentOptions: ['jointly', 'jointly-severally'],
      commencementOptions: ['notwithstanding', 'incapacity'],
    }

    const defaultValues = {
      attorneys: [],
      substituteAttorneys: [],
      attorneysAppointment: null,
      substituteAppointment: null,
      conditions: null,
      commencement: null,
    }

    return {
      attorneys: doc.attorneys ? doc.attorneys : defaultValues.attorneys,
      substituteAttorneys: doc.substituteAttorneys
        ? doc.substituteAttorneys
        : defaultValues.substituteAttorneys,
      attorneysAppointment:
        doc.attorneysAppointment &&
        options.appointmentOptions.includes(doc.attorneysAppointment)
          ? doc.attorneysAppointment
          : defaultValues.attorneysAppointment,
      substituteAppointment:
        doc.substituteAppointment &&
        options.appointmentOptions.includes(doc.substituteAppointment)
          ? doc.substituteAppointment
          : defaultValues.substituteAppointment,
      conditions: doc.conditions ? doc.conditions : defaultValues.conditions,
      commencement:
        doc.commencement &&
        options.commencementOptions.includes(doc.commencement)
          ? doc.commencement
          : defaultValues.commencement,
    }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
