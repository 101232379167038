const state = {
  willSteps: [
    {
      title: 'About You',
      key: 'about',
      subtitle: 'Tell us about yourself',
      progress: ['about', 'partner'],
    },
    {
      title: 'Guardians',
      key: 'guardians',
      subtitle: 'Choose guardians for your children',
      skip: 'children',
      progress: ['children'],
    },
    {
      title: 'Pets',
      subtitle: 'Make plans for your pets',
      key: 'pets',
      skip: 'pets',
      progress: ['pets'],
    },
    {
      title: 'Executors',
      subtitle: 'Select the executors of your Will',
      key: 'executors',
      progress: ['executors'],
    },
    {
      title: 'Divide Estate',
      subtitle: 'Choose who will inherit your estate',
      key: 'estate',
      progress: ['estate'],
    },
    {
      title: 'Gifts',
      subtitle: 'Leave gifts to family, friends and charities',
      key: 'gifts',
      skip: 'gifts',
      progress: ['gifts'],
    },
    {
      title: 'Funeral Wishes',
      subtitle: 'Let your wishes be known',
      key: 'wishes',
      progress: ['wishes'],
    },
  ],
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
