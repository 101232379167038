import { vic_mtdm_required } from '../../../../config/poaRequiredFields'
import store from '../../../index'
import entityArrayComplete from './utils'

const getters = {
  vic_mtdm_is_valid: ({ vault }) => {
    const doc = vault.find((o) => o.id === 'poaVicMtdm') || {}
    const people = store.getters['account/entities_people']
    // if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
    if (!doc.decisionMakers?.length) return false
    if (!entityArrayComplete(doc.decisionMakers, people, vic_mtdm_required))
      return false
    return true
  },
  poa_vic_mtdm: ({ vault }) => {
    const doc = vault.find((o) => o.id === 'poaVicMtdm') || {}

    const defaultValues = {
      decisionMakers: [],
      conditions: null,
    }

    return {
      decisionMakers: doc.decisionMakers
        ? doc.decisionMakers
        : defaultValues.decisionMakers,
      conditions: doc.conditions ? doc.conditions : defaultValues.conditions,
    }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
