import { tas_eg_required } from '../../../../config/poaRequiredFields'
import store from '../../../index'
import entityArrayComplete from './utils'

const getters = {
  tas_eg_is_valid: ({ vault }) => {
    const doc = vault.find((o) => o.id === 'poaTasEg') || {}
    const people = store.getters['account/entities_people']
    // if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
    if (!doc.guardians?.length) return false
    if (!entityArrayComplete(doc.guardians, people, tas_eg_required))
      return false
    if (
      doc.substitute &&
      !entityArrayComplete([doc.substitute], people, tas_eg_required)
    )
      return false
    return true
  },
  poa_tas_eg: ({ vault }) => {
    const doc = vault.find((o) => o.id === 'poaTasEg') || {}

    const defaultValues = {
      guardians: [],
      substitute: null,
      conditions: null,
      advanceCareDirective: {
        date: null,
        registered: null,
        registrationNumber: null,
      },
    }

    return {
      guardians: doc.guardians ? doc.guardians : defaultValues.guardians,
      substitute: doc.substitute ? doc.substitute : defaultValues.substitute,
      conditions: doc.conditions ? doc.conditions : defaultValues.conditions,
      advanceCareDirective: doc.advanceCareDirective
        ? {
            date: doc.advanceCareDirective.date
              ? doc.advanceCareDirective.date
              : defaultValues.advanceCareDirective.date,
            registered: doc.advanceCareDirective.registered
              ? doc.advanceCareDirective.registered
              : defaultValues.advanceCareDirective.registered,
            registrationNumber: doc.advanceCareDirective.registrationNumber
              ? doc.advanceCareDirective.registrationNumber
              : defaultValues.advanceCareDirective.registrationNumber,
          }
        : defaultValues.advanceCareDirective,
    }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
