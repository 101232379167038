<template>
  <svg
    height="100%"
    class="done-icon"
    viewBox="0 0 26 26"
    fill="none"
  >
    <circle
      cx="13"
      cy="13"
      r="13"
      fill="#008656"
    />
    <path
      d="M6.93359 12.8127L10.8542 16.7333L19.0876 8.5"
      stroke="white"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'DoneIcon',
}
</script>

<style>
.done-icon {
  width: auto;
}
</style>
