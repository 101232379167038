import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import('@/v2/layouts/CheckoutPage'),
    meta: { requiresAuth: true },
  },
  {
    path: '/',
    component: () => import('@/v2/layouts/Default'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'vault',
        name: 'Vault',
        component: () => import('@/v2/pages/Vault'),
        meta: {
          section: 'vault',
        },
      },
      {
        path: 'poa',
        name: 'Poa',
        component: () => import('@/v2/pages/Poa'),
        meta: {
          section: 'poa',
        },
      },
      {
        path: 'wishes',
        name: 'Wishes',
        component: () => import('@/v2/pages/Wishes'),
        meta: {
          section: 'wishes',
        },
      },
      {
        path: '',
        name: 'Will',
        component: () => import('@/v2/pages/Will'),
        meta: {
          section: 'will',
        },
      },
    ],
  },
  {
    path: '/ref',
    name: 'referrer',
    component: () => import('@/layouts/ReferrerTracking'),
  },
  {
    path: '/auth',
    name: 'authentication',
    component: () => import('@/v2/layouts/Authentication'),
    meta: { requiresAnon: true },
    children: [
      {
        path: '/get-started',
        name: 'registration',
        component: () => import('@/v2/pages/authentication/Registration'),
      },
      {
        path: '/start-your-power-of-attorney',
        name: 'poa-registration',
        component: () => import('@/v2/pages/authentication/RegistrationPoa'),
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('@/v2/pages/authentication/Registration'),
      },
      {
        path: '/login',
        name: 'login',
        component: () => import('@/v2/pages/authentication/Login'),
      },
      {
        path: '/reset-password',
        name: 'reset-password',
        props: (route) => ({ actionCode: route.query?.oobCode }),
        component: () => import('@/v2/pages/authentication/ResetPassword'),
      },
    ],
  },
  {
    path: '/em/mytributes',
    name: 'MyTributes',
    component: () => import('@/layouts/embed/MyTributes'),
    meta: { disableChat: true },
  },
  { path: '*', component: () => import('@/pages/Error404') },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  /*scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 100)
    })
  },*/
})

export default router
