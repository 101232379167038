import { act_epa_required } from '../../../../config/poaRequiredFields'
import store from '../../../index'
import entityArrayComplete from './utils'

const getters = {
  act_epa_is_valid: ({ vault }) => {
    const doc = vault.find((o) => o.id === 'poaActEpa') || {}
    const people = store.getters['account/entities_people']
    // if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
    if (!doc.attorneys?.length) return false
    if (!entityArrayComplete(doc.attorneys, people, act_epa_required))
      return false
    if (
      doc.substitutes &&
      !entityArrayComplete(doc.substitutes, people, act_epa_required)
    )
      return false
    if (
      !doc.commencement ||
      !doc.attorneyAuthoriseOther ||
      !doc.refuseMedicalTreatment
    )
      return false
    if (
      !(
        doc.functions?.property ||
        doc.functions?.healthCare ||
        doc.functions?.personalCare ||
        doc.functions?.medicalResearch
      )
    )
      return false
    if (doc.commencement === 'from-date') {
      if (!doc.commencementDate) return false
      const currentDate = new Date()
      const commencementDate = new Date(
        doc.commencementDate.year,
        doc.commencementDate.month - 1,
        doc.commencementDate.day
      )
      if (commencementDate.getTime() < currentDate.getTime()) return false
    }
    return true
  },
  poa_act_epa: ({ vault }) => {
    const doc = vault.find((o) => o.id === 'poaActEpa') || {}

    const options = {
      appointmentOptions: [
        'together',
        'separately',
        'together-and-separately',
        'other',
      ],
      authorityOptions: ['none', 'any', 'specified'],
      refuseMedicalTreatmentOptions: ['must-not', 'may-generally', 'some'],
      commencementOptions: [
        'immediately',
        'from-date',
        'from-event',
        'impaired',
      ],
    }

    const defaultValues = {
      attorneys: [],
      substitutes: [],
      appointment: null,
      appointmentOther: null,
      attorneyAuthoriseOther: null,
      attorneyAuthoriseOtherSpecified: null,
      functions: {
        property: null,
        personalCare: null,
        healthCare: null,
        medicalResearch: null,
        propertyConditions: null,
        personalCareConditions: null,
        healthCareConditions: null,
        medicalResearchConditions: null,
      },
      refuseMedicalTreatment: null,
      refuseMedicalTreatmentSome: null,
      commencement: null,
      commencementDate: null,
      commencementEvent: null,
      marriage: null,
    }

    return {
      attorneys: doc.attorneys ? doc.attorneys : defaultValues.attorneys,
      substitutes: doc.substitutes
        ? doc.substitutes
        : defaultValues.substitutes,
      appointment:
        doc.appointment && options.appointmentOptions.includes(doc.appointment)
          ? doc.appointment
          : defaultValues.appointment,
      appointmentOther: doc.appointmentOther
        ? doc.appointmentOther
        : defaultValues.appointmentOther,
      attorneyAuthoriseOther:
        doc.attorneyAuthoriseOther &&
        options.authorityOptions.includes(doc.attorneyAuthoriseOther)
          ? doc.attorneyAuthoriseOther
          : defaultValues.attorneyAuthoriseOther,
      attorneyAuthoriseOtherSpecified: doc.attorneyAuthoriseOtherSpecified
        ? doc.attorneyAuthoriseOtherSpecified
        : defaultValues.attorneyAuthoriseOtherSpecified,
      functions: {
        property:
          doc.functions && doc.functions.property
            ? doc.functions.property
            : defaultValues.functions.property,
        personalCare:
          doc.functions && doc.functions.personalCare
            ? doc.functions.personalCare
            : defaultValues.functions.personalCare,
        healthCare:
          doc.functions && doc.functions.healthCare
            ? doc.functions.healthCare
            : defaultValues.functions.healthCare,
        medicalResearch:
          doc.functions && doc.functions.medicalResearch
            ? doc.functions.medicalResearch
            : defaultValues.functions.medicalResearch,
        propertyConditions:
          doc.functions && doc.functions.propertyConditions
            ? doc.functions.propertyConditions
            : defaultValues.functions.propertyConditions,
        personalCareConditions:
          doc.functions && doc.functions.personalCareConditions
            ? doc.functions.personalCareConditions
            : defaultValues.functions.personalCareConditions,
        healthCareConditions:
          doc.functions && doc.functions.healthCareConditions
            ? doc.functions.healthCareConditions
            : defaultValues.functions.healthCareConditions,
        medicalResearchConditions:
          doc.functions && doc.functions.medicalResearchConditions
            ? doc.functions.medicalResearchConditions
            : defaultValues.functions.medicalResearchConditions,
      },
      refuseMedicalTreatment:
        doc.refuseMedicalTreatment &&
        options.refuseMedicalTreatmentOptions.includes(
          doc.refuseMedicalTreatment
        )
          ? doc.refuseMedicalTreatment
          : defaultValues.refuseMedicalTreatment,
      refuseMedicalTreatmentSome: doc.refuseMedicalTreatmentSome
        ? doc.refuseMedicalTreatmentSome
        : defaultValues.refuseMedicalTreatmentSome,
      commencement:
        doc.commencement &&
        options.commencementOptions.includes(doc.commencement)
          ? doc.commencement
          : defaultValues.commencement,
      commencementDate: doc.commencementDate
        ? doc.commencementDate
        : defaultValues.commencementDate,
      commencementEvent: doc.commencementEvent
        ? doc.commencementEvent
        : defaultValues.commencementEvent,
      marriage: doc.marriage ? doc.marriage : defaultValues.marriage,
    }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
