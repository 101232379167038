import { chain } from 'lodash'

import { db, fb, serverTimestamp } from '../../../../plugins/firebase'

const getters = {
  vault_assets_groupby_type: (_, getters) => {
    const data = []
    Object.entries(getters.vault('assets')).forEach(([key, value]) =>
      data.push({ id: key, ...value })
    )
    return chain(data)
      .groupBy((x) => x.type)
      .value()
  },
  vault_assets: (_, getters) => {
    const data = []
    Object.entries(getters.vault('assets')).forEach(([key, value]) =>
      data.push({ id: key, ...value })
    )
    return chain(data)
      .orderBy((x) => x.name)
      .value()
  },
}

const actions = {
  save_vault_assets: async ({ getters }, { id, estate }) => {
    const { uid } = getters.auth_user

    const propId = id || window.generatePushId()

    const docRef = db
      .collection('usersV2')
      .doc(uid)
      .collection('vault')
      .doc('assets')

    let data = {
      [propId]: estate ? { ...estate } : fb.firestore.FieldValue.delete(),
    }

    if (!id) {
      data[propId].createdAt = serverTimestamp
    }

    return await docRef.set(data, { merge: true })
  },
}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
