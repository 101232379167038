<template>
  <svg
    height="100%"
    class="plus-icon"
    viewBox="0 0 14 15"
    fill="none"
  >
    <path
      d="M6.97077 13.4385L6.97077 1.79485"
      stroke-width="2"
    />
    <path
      d="M12.7925 7.61663L1.14886 7.61663"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'DoneIcon',
}
</script>

<style lang="scss">
.plus-icon {
  width: auto;
  path {
    stroke: currentColor;
  }
}
</style>
