const state = {
  memorialisation: [
    {
      key: 'burialLocationType',
      question: 'Where would you like your body to be buried?',
      remains: ['burial'],
      options: ['Grave', 'Crypt', 'Let Executors Decide', 'Unsure'],
    },
    {
      key: 'burialLocationOfPlot',
      question: 'What is your preferred location?',
      description: 'Cemetery name, Address, Cemetery area & Plot number',
      related: 'burialLocationType',
      remains: ['burial'],
      showIf: (a) => ['Grave', 'Crypt'].includes(a),
    },
    {
      key: 'preferredStyleOfCoffin',
      question: 'Preferred style of coffin or casket?',
      remains: ['burial'],
      options: [
        'Solid Timber',
        'Metal',
        'Wood Veneer',
        'Eco-friendly',
        'Unsure',
      ],
    },
    {
      key: 'clothesOptions',
      question: 'What clothes or jewellery would you like to be dressed in?',
      remains: ['burial'],
    },
    {
      key: 'headstoneMessage',
      question: 'What would you like your headstone or plaque to say?',
      remains: ['burial'],
    },
    {
      key: 'endOfTheAshes',
      question: 'Where would you like your ashes to go?',
      remains: ['cremated'],
      options: ['Scattered', 'Buried', 'Interred', 'Unsure'],
    },
    {
      key: 'preferredStyleOfUrn',
      question: 'Preferred style of urn?',
      options: ['Wall Niche', 'Rose Garden', 'Family Estate', 'Unsure'],
      remains: ['cremated'],
      related: 'endOfTheAshes',
      showIf: (a) => a === 'Interred',
    },
    {
      key: 'crematedUrnLocationOfPlace',
      question: 'What is your preferred location?',
      related: 'preferredStyleOfUrn',
      remains: ['cremated'],
      showIf: (a) => ['Wall Niche', 'Rose Garden', 'Family Estate'].includes(a),
    },
    {
      key: 'crematedLocationOfPlace',
      question: 'What is your preferred location?',
      related: 'endOfTheAshes',
      remains: ['cremated'],
      showIf: (a) => ['Scattered', 'Buried', 'Other'].includes(a),
    },

    {
      key: 'inscriptionMessage',
      question: 'If there is an inscription, what would you like it to say?',
      remains: ['cremated'],
    },
    {
      key: 'scienceLocationOfDonation',
      question: 'Where would you like your body donated (if known)?',
      remains: ['science'],
    },
    {
      key: 'dispositionModeOfDonation',
      question: 'What is your preferred mode of disposition?',
      options: ['Burial', 'Cremation'],
      remains: ['science'],
    },
  ],
  services: [
    {
      key: 'funeralKindOfService',
      question: `What kind of service would you like?`,
      description:
        'For example a traditional service, a celebration of your life, an intimate affair',
    },
    {
      key: 'funeralHeldLocation',
      question: 'Where would you like your funeral or memorial to be held?',
      options: [
        'Church',
        'Chapel',
        'Funeral home',
        'Private service',
        'Local park',
        'Other',
      ],
    },
    {
      key: 'funeralHeldLocationOther',
      question: 'Please specify',
      showIf: (a) => a === 'Other',
      related: 'funeralHeldLocation',
    },
    {
      key: 'funeralConductionOfService',
      question:
        'Is there a clergy person or celebrant you would like to conduct the service?',
    },
    {
      key: 'funeralSpeaker',
      question: 'Who should speak at the service?',
    },
    {
      key: 'funeralPeopleWear',
      question: 'What would you like people to wear?',
    },
    {
      key: 'funeralVersesOrReadings',
      question: 'Are there any verses or readings you prefer?',
    },
    {
      key: 'funeralFlowersAndColor',
      question: 'Which flowers or colours do you like?',
    },
    {
      key: 'funeralMemorialBook',
      question: 'Would you like a memorial book?',
    },
    {
      key: 'funeralMusic',
      question: 'Are there any particular songs you’d like played?',
    },
    {
      key: 'funeralServiceCatering',
      question: 'Would you like the service catered?',
    },
    {
      key: 'funeralFinerOrganizer',
      question:
        'Who would you like to take care of the finer details of your funeral when the time comes?',
    },
    {
      key: 'funeralFoodAndDrinks',
      question: 'What food and drink would you like served afterwards?',
    },
    {
      key: 'funeralNewspaperAnnouncement',
      question:
        'Would you like a newspaper announcement of your death and/or service? Do you have a preferred newspaper?',
    },
    {
      key: 'funeralDonation',
      question: 'Would you like donations made in your honour?',
      options: ['Yes', 'No'],
    },
    {
      key: 'funeralDonationExplanation',
      question: 'Please specify',
      showIf: (a) => a === 'Yes',
      related: 'funeralDonation',
    },
    {
      key: 'funeralAnythingRememberForget',
      question:
        'Is there anything that you would like people to remember about you, that others might forget?',
    },
  ],
  prepaidFuneral: [
    {
      key: 'insurance',
      active: true,
      label: 'Funeral Insurance',
    },
    {
      key: 'prepaid',
      active: true,
      label: 'Prepaid Funeral',
    },
    {
      key: 'bond',
      active: true,
      label: 'Funeral Bond',
    },
    {
      key: 'none',
      active: true,
      label: 'None',
    },
  ],
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
