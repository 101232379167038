import Vue from 'vue'

Vue.mixin({
  data: function () {
    return {
      btnProcessing: {
        loading: true,
        color: 'primary',
        class: 'black--text text-capitalize px-12 text-lg',
        height: 64,
        ripple: false,
      },
      btnInactive: {
        color: 'grey lighten-3 text-capitalize px-12 text-lg',
        ripple: true,
        height: 64,
      },
      btnActive: {
        color: 'primary',
        ripple: true,
        class: 'black--text text-capitalize px-12 text-lg',
        height: 64,
      },
      backButton: {
        text: true,
        color: 'black',
        class: 'text-capitalize',
      },
      largeButton: {
        depressed: true,
        color: 'primary',
        class: 'text-capitalize display-2 px-16 large-button black--text',
        height: 88,
      },
      smallButton: {
        depressed: true,
        color: 'primary',
        class: 'text-capitalize display-1 px-8 black--text',
        height: 56,
      },
      outlineButton: {
        outlined: true,
        text: true,
        class: 'text-capitalize display-1 px-7 outline-button',
        height: 48,
      },
      textField: {
        outlined: true,
        'background-color': 'white',
        height: 64,
        label: '',
        placeholder: '',
      },
      textAreaField: {
        outlined: true,
        'background-color': 'white',
        label: '',
        placeholder: '',
      },
      textAreaFieldGrow: {
        outlined: true,
        'background-color': 'white',
        label: '',
        placeholder: '',
      },
      textAreaFieldShort: {
        outlined: true,
        'background-color': 'white',
        rows: 3,
        dense: true,
        label: '',
        placeholder: '',
      },
      appBar: {
        flat: true,
        class: 'white',
        height: 180,
      },
    }
  },
})
