import { nsw_epa_required } from '../../../../config/poaRequiredFields'
import store from '../../../index'
import entityArrayComplete from './utils'

const getters = {
  nsw_epa_is_valid: ({ vault }) => {
    const doc = vault.find((o) => o.id === 'poaNswEpa') || {}
    const people = store.getters['account/entities_people']
    // if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
    if (!doc.attorneys?.length) return false
    if (!doc.commencement) return false
    if (!entityArrayComplete(doc.attorneys, people, nsw_epa_required))
      return false
    if (doc.substitutes?.length > 0) {
      if (
        !entityArrayComplete(
          doc.substitutes.map((s) => s.entity),
          people,
          nsw_epa_required
        )
      )
        return false
    }
    if (doc.authorisations?.benefitsPeople?.length > 0) {
      if (
        !entityArrayComplete(
          doc.authorisations.benefitsPeople,
          people,
          nsw_epa_required
        )
      )
        return false
    }
    return true
  },
  poa_nsw_epa: ({ vault }) => {
    const doc = vault.find((o) => o.id === 'poaNswEpa') || {}

    const options = {
      appointmentOptions: ['jointly', 'jointly-severally'],
      commencementOptions: [
        'attorney-appointed',
        'medical-practitioner',
        'need-assistance',
        'other',
      ],
      substitutesStyleOptions: ['none', 'general', 'per-primary'],
    }

    const defaultValues = {
      attorneys: [],
      appointment: null,
      appointmentTermination: null,
      substitutesStyle: 'none',
      substitutes: [],
      substituteAppointment: null,
      substituteAppointmentTermination: null,
      authorisations: {
        reasonableGifts: null,
        benefitsAttorney: null,
        benefitsPeople: [],
      },
      conditions: null,
      commencement: null,
      commencementOther: null,
    }

    return {
      attorneys: doc.attorneys ? doc.attorneys : defaultValues.attorneys,
      appointment:
        doc.appointment && options.appointmentOptions.includes(doc.appointment)
          ? doc.appointment
          : defaultValues.appointment,
      appointmentTermination: doc.appointmentTermination
        ? doc.appointmentTermination
        : defaultValues.appointmentTermination,
      substitutesStyle:
        doc.substitutesStyle &&
        options.substitutesStyleOptions.includes(doc.substitutesStyle)
          ? doc.substitutesStyle
          : defaultValues.substitutesStyle,
      substitutes: doc.substitutes
        ? doc.substitutes
        : defaultValues.substitutes,
      substituteAppointment: doc.substituteAppointment
        ? doc.substituteAppointment
        : defaultValues.substituteAppointment,
      substituteAppointmentTermination: doc.substituteAppointmentTermination
        ? doc.substituteAppointmentTermination
        : defaultValues.substituteAppointmentTermination,
      authorisations: doc.authorisations
        ? {
            reasonableGifts: doc.authorisations.reasonableGifts
              ? doc.authorisations.reasonableGifts
              : defaultValues.authorisations.reasonableGifts,
            benefitsAttorney: doc.authorisations.benefitsAttorney
              ? doc.authorisations.benefitsAttorney
              : defaultValues.authorisations.benefitsAttorney,
            benefitsPeople: doc.authorisations.benefitsPeople
              ? doc.authorisations.benefitsPeople
              : defaultValues.authorisations.benefitsPeople,
          }
        : defaultValues.authorisations,
      conditions: doc.conditions ? doc.conditions : defaultValues.conditions,
      commencement:
        doc.commencement &&
        options.commencementOptions.includes(doc.commencement)
          ? doc.commencement
          : defaultValues.commencement,
      commencementOther: doc.commencementOther
        ? doc.commencementOther
        : defaultValues.commencementOther,
    }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
