const state = {
  professionalExecutors: [
    {
      name: 'Australian Unity Trustees',
      key: 'ausUnity',
      type: 'company',
    },
  ],
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
