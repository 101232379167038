import { db } from '../../../../plugins/firebase'

const getters = {
  will_progress_completed_sections: ({ data }) => {
    const progress = data?.progress || {}
    const allowedFields = [
      'about',
      'partner',
      'children',
      'pets',
      'executors',
      'estate',
      'gifts',
      'wishes',
    ]
    const requiredFields = [
      'about',
      'partner',
      'children',
      'pets',
      'executors',
      'estate',
      'gifts',
      'wishes',
    ]

    const value = Object.keys(progress).filter((k) => {
      return allowedFields.includes(k) && progress[k]
    })

    return {
      value: value,
      isComplete: requiredFields.every((field) => value.includes(field)),
    }
  },
  will_steps_progress: ({ data, lists }) => {
    const progress = data?.progress || {}
    const steps = lists.willSteps
    let stepsProgress = steps.map((o, i) => {
      return {
        ...o,
        isStepComplete: o.progress.every((k) => progress[k]),
        isDataComplete: true,
        isLastStep: i === steps.length - 1,
      }
    })

    let currentStep = stepsProgress.reduce((t, n, i) => {
      if (i > t) {
        return t
      }

      return n.isStepComplete ? t : i
    }, stepsProgress.length)

    return {
      steps: stepsProgress,
      isComplete: stepsProgress.every((step) => step.isStepComplete),
      currentStep: currentStep,
    }
  },
}

const actions = {
  async update_will_progress({ getters }, sections) {
    const completed = getters.will_progress_completed_sections.value
    if (sections.every((val) => completed.includes(val))) {
      return true
    }

    const { uid } = getters.auth_user
    const docRef = db.collection('usersV2').doc(uid)

    const allowedFields = [
      'about',
      'partner',
      'children',
      'pets',
      'executors',
      'estate',
      'gifts',
      'wishes',
    ]

    const updates = sections
      .filter((section) => {
        return allowedFields.includes(section)
      })
      .reduce((ac, a) => {
        return { ...ac, [a]: true }
      }, {})

    await docRef.set(
      {
        progress: updates,
      },
      { merge: true }
    )

    // If all complete trigger tagmanager event
    if (
      allowedFields.every((k) => {
        return completed.includes(k) || sections.includes(k)
      })
    ) {
      let auth = getters.auth_user
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'willCompleted',
        userId: auth.uid,
        userEmail: auth.email,
      })
    }

    return true
  },
}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
