import { nt_app_required } from '../../../../config/poaRequiredFields'
import store from '../../../index'
import entityArrayComplete from './utils'

const getters = {
  nt_app_is_valid: ({ vault }) => {
    const doc = vault.find((o) => o.id === 'poaNtApp') || {}
    const people = store.getters['account/entities_people']
    // if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
    if (
      doc.decisionMakers &&
      !entityArrayComplete(
        doc.decisionMakers.map((d) => d.entity),
        people,
        nt_app_required
      )
    )
      return false
    if (doc.advanceConsentDecision?.cpr === null) return false
    if (!doc.advanceConsentDecision?.unwantedMedicalTreatments) return false
    if (
      doc.advanceConsentDecision?.unwantedMedicalTreatments.feeding === null &&
      doc.advanceConsentDecision?.unwantedMedicalTreatments.dialysis === null &&
      doc.advanceConsentDecision?.unwantedMedicalTreatments.transfusions ===
        null &&
      doc.advanceConsentDecision?.unwantedMedicalTreatments.other === null
    )
      return false
    return true
  },
  poa_nt_app: ({ vault }) => {
    const doc = vault.find((o) => o.id === 'poaNtApp') || {}

    const options = {
      locationOfDeath: ['home', 'hospital', 'other'],
      decisionsMade: ['severally', 'jointly', 'other'],
    }

    const defaultValues = {
      advanceCareStatement: {
        lifeMeaning: null,
        goalsPriorities: null,
        unacceptable: null,
        consentToCare: null,
        locationOfDeath: null,
        homeLocation: null,
        otherLocation: null,
        otherInformation: null,
        spiritualRequests: null,
        afterDeathImportant: null,
      },
      advanceConsentDecision: {
        cpr: null,
        cprUnacceptableOutcomes: null,
        unwantedMedicalTreatments: {
          feeding: null,
          dialysis: null,
          transfusions: null,
          other: null,
        },
        beliefsAffectingTreatment: null,
      },
      decisionMakers: [],
      decisionsMade: null,
      decisionsMadeOther: null,
    }

    return {
      advanceCareStatement: doc.advanceCareStatement
        ? {
            lifeMeaning: doc.advanceCareStatement.lifeMeaning
              ? doc.advanceCareStatement.lifeMeaning
              : defaultValues.advanceCareStatement.lifeMeaning,
            goalsPriorities: doc.advanceCareStatement.goalsPriorities
              ? doc.advanceCareStatement.goalsPriorities
              : defaultValues.advanceCareStatement.goalsPriorities,
            unacceptable: doc.advanceCareStatement.unacceptable
              ? doc.advanceCareStatement.unacceptable
              : defaultValues.advanceCareStatement.unacceptable,
            consentToCare:
              doc.advanceCareStatement.consentToCare !== null
                ? doc.advanceCareStatement.consentToCare
                : defaultValues.advanceCareStatement.consentToCare,
            locationOfDeath:
              doc.advanceCareStatement.locationOfDeath &&
              options.locationOfDeath.includes(
                doc.advanceCareStatement.locationOfDeath
              )
                ? doc.advanceCareStatement.locationOfDeath
                : defaultValues.advanceCareStatement.locationOfDeath,
            homeLocation: doc.advanceCareStatement.homeLocation
              ? doc.advanceCareStatement.homeLocation
              : defaultValues.advanceCareStatement.homeLocation,
            otherLocation: doc.advanceCareStatement.otherLocation
              ? doc.advanceCareStatement.otherLocation
              : defaultValues.advanceCareStatement.otherLocation,
            otherInformation: doc.advanceCareStatement.otherInformation
              ? doc.advanceCareStatement.otherInformation
              : defaultValues.advanceCareStatement.otherInformation,
            spiritualRequests: doc.advanceCareStatement.spiritualRequests
              ? doc.advanceCareStatement.spiritualRequests
              : defaultValues.advanceCareStatement.spiritualRequests,
            afterDeathImportant: doc.advanceCareStatement.afterDeathImportant
              ? doc.advanceCareStatement.afterDeathImportant
              : defaultValues.advanceCareStatement.afterDeathImportant,
          }
        : defaultValues.advanceCareStatement,
      advanceConsentDecision: doc.advanceConsentDecision
        ? {
            cpr:
              doc.advanceConsentDecision.cpr !== null
                ? doc.advanceConsentDecision.cpr
                : defaultValues.advanceConsentDecision.cpr,
            cprUnacceptableOutcomes: doc.advanceConsentDecision
              .cprUnacceptableOutcomes
              ? doc.advanceConsentDecision.cprUnacceptableOutcomes
              : defaultValues.advanceConsentDecision.cprUnacceptableOutcomes,
            unwantedMedicalTreatments: {
              feeding:
                doc.advanceConsentDecision.unwantedMedicalTreatments.feeding !==
                null
                  ? doc.advanceConsentDecision.unwantedMedicalTreatments.feeding
                  : defaultValues.advanceConsentDecision
                      .unwantedMedicalTreatments.feeding,
              dialysis:
                doc.advanceConsentDecision.unwantedMedicalTreatments
                  .dialysis !== null
                  ? doc.advanceConsentDecision.unwantedMedicalTreatments
                      .dialysis
                  : defaultValues.advanceConsentDecision
                      .unwantedMedicalTreatments.dialysis,
              transfusions:
                doc.advanceConsentDecision.unwantedMedicalTreatments
                  .transfusions !== null
                  ? doc.advanceConsentDecision.unwantedMedicalTreatments
                      .transfusions
                  : defaultValues.advanceConsentDecision
                      .unwantedMedicalTreatments.transfusions,
              other: doc.advanceConsentDecision.unwantedMedicalTreatments.other
                ? doc.advanceConsentDecision.unwantedMedicalTreatments.other
                : defaultValues.advanceConsentDecision.unwantedMedicalTreatments
                    .other,
            },
            beliefsAffectingTreatment: doc.advanceConsentDecision
              .beliefsAffectingTreatment
              ? doc.advanceConsentDecision.beliefsAffectingTreatment
              : defaultValues.advanceConsentDecision.beliefsAffectingTreatment,
          }
        : defaultValues.advanceConsentDecision,
      decisionMakers: doc.decisionMakers
        ? doc.decisionMakers
        : defaultValues.decisionMakers,
      decisionsMade:
        doc.decisionsMade && options.decisionsMade.includes(doc.decisionsMade)
          ? doc.decisionsMade
          : defaultValues.decisionsMade,
      decisionsMadeOther: doc.decisionsMadeOther
        ? doc.decisionsMadeOther
        : defaultValues.decisionsMadeOther,
    }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
