<template>
  <svg
    height="100%"
    class="menu-icon"
    viewBox="0 0 24 16"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24 2H0V0H24V2ZM24 9H0V7H24V9ZM0 16H24V14H0V16Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'DoneIcon',
}
</script>

<style lang="scss">
.menu-icon {
  width: auto;
  path {
    fill: currentColor;
  }
}
</style>
