const state = {
  months: [
    {
      label: 'January',
      key: 1,
    },
    {
      label: 'February',
      key: 2,
    },
    {
      label: 'March',
      key: 3,
    },
    {
      label: 'April',
      key: 4,
    },
    {
      label: 'May',
      key: 5,
    },
    {
      label: 'June',
      key: 6,
    },
    {
      label: 'July',
      key: 7,
    },
    {
      label: 'August',
      key: 8,
    },
    {
      label: 'September',
      key: 9,
    },
    {
      label: 'October',
      key: 10,
    },
    {
      label: 'November',
      key: 11,
    },
    {
      label: 'December',
      key: 12,
    },
  ],
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
