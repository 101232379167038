<template>
  <svg
    height="100%"
    class="single-icon"
    viewBox="0 0 13 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 9.27689C9.01875 9.27689 11.05 7.20615 11.05 4.63844C11.05 2.07073 9.01875 0 6.5 0C3.98125 0 1.95 2.07073 1.95 4.63844C1.95 7.20615 3.98125 9.27689 6.5 9.27689ZM6.5 11.3752C2.89792 11.3752 0 14.219 0 17.6703V20H13V17.6703C13 14.219 10.1021 11.3752 6.5 11.3752Z"
    />
  </svg>
</template>

<script>
export default {
  name: 'DoneIcon',
}
</script>

<style lang="scss">
.single-icon {
  width: auto;
  path {
    fill: currentColor;
  }
}
</style>
