<template>
  <div class="w-box">
    <div
      v-if="title"
      class="w-title mb-6"
    >
      {{ title }}
    </div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Box',
  props: {
    title: {
      default: null,
    },
  },
}
</script>

<style lang="scss">
.w-box {
  box-shadow: 3px 12px 50px rgba(30, 58, 84, 0.12);
  border-radius: 6px;
  padding: 64px 48px;
  background-color: #ffffff;
  position: relative;
}
</style>
