import Vue from 'vue'

import { anonFields, referrerTags } from '@/config/constants'

export default {
  namespaced: true,

  state: {
    referral: null,
    partnerToken: null,
    data: {},
  },

  getters: {
    getReferralTracking: (state) => {
      return state.referral?.id ? state.referral : null
    },
    getPartnerToken: (state) => {
      return state.partnerToken || null
    },
    getAnonData: (state) => {
      return state.data
    },
  },

  mutations: {
    SET_REFERRAL_TRACKING(state, payload) {
      if (payload.id) {
        if (typeof payload.id === 'string' && payload.id.length <= 32) {
          const referral = {}
          referrerTags.forEach((keys) => {
            if (payload[keys.key]) {
              referral[keys.key] = payload[keys.key]
            }
          })
          Vue.set(state, 'referral', referral)
        }
      } else {
        Vue.set(state, 'referral', null)
      }
    },
    SET_ANON_DATA(state, payload) {
      if (anonFields.includes(payload.key)) {
        Vue.set(state.data, payload.key, payload.value)
      }
    },
    RESET_ANON(state) {
      Vue.set(state, 'referral', null)
      Vue.set(state, 'data', {})
      Vue.set(state, 'partnerToken', null)
    },
    SET_PARTNER_TOKEN(state, token) {
      Vue.set(state, 'partnerToken', token)
    },
  },

  actions: {},
}
