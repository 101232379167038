// required field for each poa types entities.
// not in vuex due to it being required in /store/account/modules/poa/utils.js
// + the components. attempted to access these static vars with getters but
// couldn't get it to load before mount, where it was needed and may later be
// required. also slightly better perf this way. this is single source of truth

// if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
const nsw_epa_required = ['name', 'address']
const nsw_eg_required = ['name', 'address', 'occupation', 'phone']
const act_epa_required = ['name', 'address']
const nt_app_required = ['name', 'address', 'email', 'phone']
const qld_epa_required = ['name', 'address', 'phone', 'email']
const sa_acd_required = ['name', 'address', 'phone', 'dob']
const sa_epa_required = ['name', 'address']
const tas_epa_required = ['name', 'address']
const tas_eg_required = ['name', 'address', 'occupation', 'phone']
const vic_epa_required = ['name', 'address']
const vic_mtdm_required = ['name', 'address', 'dob', 'phone']
const wa_epa_required = ['name', 'address']
const wa_epg_required = ['name', 'address']

export {
  nsw_epa_required,
  nsw_eg_required,
  act_epa_required,
  nt_app_required,
  qld_epa_required,
  sa_acd_required,
  sa_epa_required,
  tas_epa_required,
  tas_eg_required,
  vic_epa_required,
  vic_mtdm_required,
  wa_epa_required,
  wa_epg_required,
}
