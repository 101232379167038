<template>
  <svg
    height="100%"
    class="plusbig-icon"
    viewBox="0 0 42 42"
    fill="none"
  >
    <path
      d="M21 0.5V41.5"
      stroke-width="2"
    />
    <path
      d="M0.5 21L41.5 21"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'PlusbigIcon',
}
</script>

<style lang="scss">
.plusbig-icon {
  width: auto !important;
  path {
    stroke: currentColor;
  }
}
</style>
