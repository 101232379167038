const state = {
  backupBeneficiaries: [
    {
      key: 'theirChildren',
      active: true,
      label: 'Their Children',
      text: 'The children of this beneficiary',
    },
    {
      key: 'myBeneficiaries',
      active: true,
      label: 'My beneficiaries',
      text: 'The surviving beneficiaries of my Will',
    },
    {
      key: 'newBeneficiaries',
      active: true,
      label: 'New beneficiaries',
      text: 'Select replacement beneficiaries',
      requiresEntities: true,
    },
  ],
}

const getters = {}

const actions = {}

const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations,
}
