import { qld_epa_required } from '../../../../config/poaRequiredFields'
import store from '../../../index'
import entityArrayComplete from './utils'

const getters = {
  qld_epa_is_valid: ({ vault }) => {
    const doc = vault.find((o) => o.id === 'poaQldEpa') || {}
    const people = store.getters['account/entities_people']
    // if changed, also change in /portal/src/components/areas/manageCustomers/PoaManagement.vue
    if (!doc.attorneys?.length || !doc.commencement) return false
    if (!entityArrayComplete(doc.attorneys, people, qld_epa_required))
      return false
    if (
      doc.personalNotify?.other &&
      !entityArrayComplete(doc.personalNotify.other, people, qld_epa_required)
    )
      return false
    if (
      doc.financialNotify?.other &&
      !entityArrayComplete(doc.financialNotify.other, people, qld_epa_required)
    )
      return false
    return true
  },
  poa_qld_epa: ({ vault }) => {
    const doc = vault.find((o) => o.id === 'poaQldEpa') || {}

    const options = {
      decisionsOptions: ['jointly', 'severally', 'majority', 'other'],
      commencementOptions: ['lack-capacity', 'immediately', 'other'],
    }

    const defaultValues = {
      wishes: null,
      attorneys: [],
      personalMatters: null,
      financialMatters: null,
      appointment: null,
      appointmentOther: null,
      terms: null,
      personalNotify: {
        me: null,
        otherAttorneys: null,
        other: [],
        what: {
          writtenNotice: null,
          otherInformation: null,
        },
      },
      commencement: null,
      commencementOther: null,
      financialNotify: {
        me: null,
        otherAttorneys: null,
        other: [],
        what: {
          writtenNotice: null,
          allRecords: null,
          aboveAmount: null,
          assets: null,
          summaries: null,
          plansAndAdvice: null,
          otherInformation: null,
        },
        when: {
          onRequest: null,
          onTimeframe: null,
          onOther: null,
        },
      },
    }

    return {
      wishes: doc.wishes ? doc.wishes : defaultValues.wishes,
      attorneys: doc.attorneys ? doc.attorneys : defaultValues.attorneys,
      financialMatters: doc.financialMatters
        ? doc.financialMatters
        : defaultValues.financialMatters,
      personalMatters: doc.personalMatters
        ? doc.personalMatters
        : defaultValues.personalMatters,
      appointment:
        doc.appointment && options.decisionsOptions.includes(doc.appointment)
          ? doc.appointment
          : defaultValues.appointment,
      appointmentOther: doc.appointmentOther
        ? doc.appointmentOther
        : defaultValues.appointmentOther,
      terms: doc.terms ? doc.terms : defaultValues.terms,
      personalNotify: doc.personalNotify
        ? {
            me: doc.personalNotify.me
              ? doc.personalNotify.me
              : defaultValues.personalNotify.me,
            otherAttorneys: doc.personalNotify.otherAttorneys
              ? doc.personalNotify.otherAttorneys
              : defaultValues.personalNotify.otherAttorneys,
            other: doc.personalNotify.other
              ? doc.personalNotify.other
              : defaultValues.personalNotify.other,
            what: doc.personalNotify.what
              ? {
                  writtenNotice: doc.personalNotify.what.writtenNotice
                    ? doc.personalNotify.what.writtenNotice
                    : defaultValues.personalNotify.what.writtenNotice,
                  otherInformation: doc.personalNotify.what.otherInformation
                    ? doc.personalNotify.what.otherInformation
                    : defaultValues.personalNotify.what.otherInformation,
                }
              : defaultValues.personalNotify.what,
          }
        : defaultValues.personalNotify,
      commencement: doc.commencement
        ? doc.commencement
        : defaultValues.commencement,
      commencementOther: doc.commencementOther
        ? doc.commencementOther
        : defaultValues.commencementOther,
      financialNotify: doc.financialNotify
        ? {
            me: doc.financialNotify.me
              ? doc.financialNotify.me
              : defaultValues.financialNotify.me,
            otherAttorneys: doc.financialNotify.otherAttorneys
              ? doc.financialNotify.otherAttorneys
              : defaultValues.financialNotify.otherAttorneys,
            other: doc.financialNotify.other
              ? doc.financialNotify.other
              : defaultValues.financialNotify.other,
            what: doc.financialNotify.what
              ? {
                  writtenNotice: doc.financialNotify.what.writtenNotice
                    ? doc.financialNotify.what.writtenNotice
                    : defaultValues.financialNotify.what.writtenNotice,
                  allRecords: doc.financialNotify.what.allRecords
                    ? doc.financialNotify.what.allRecords
                    : defaultValues.financialNotify.what.allRecords,
                  aboveAmount: doc.financialNotify.what.aboveAmount
                    ? doc.financialNotify.what.aboveAmount
                    : defaultValues.financialNotify.what.aboveAmount,
                  assets: doc.financialNotify.what.assets
                    ? doc.financialNotify.what.assets
                    : defaultValues.financialNotify.what.assets,
                  summaries: doc.financialNotify.what.summaries
                    ? doc.financialNotify.what.summaries
                    : defaultValues.financialNotify.what.summaries,
                  plansAndAdvice: doc.financialNotify.what.plansAndAdvice
                    ? doc.financialNotify.what.plansAndAdvice
                    : defaultValues.financialNotify.what.plansAndAdvice,
                  otherInformation: doc.financialNotify.what.otherInformation
                    ? doc.financialNotify.what.otherInformation
                    : defaultValues.financialNotify.what.otherInformation,
                }
              : defaultValues.financialNotify.what,
            when: doc.financialNotify.when
              ? {
                  onRequest: doc.financialNotify.when.onRequest
                    ? doc.financialNotify.when.onRequest
                    : defaultValues.financialNotify.when.onRequest,
                  onTimeframe: doc.financialNotify.when.onTimeframe
                    ? doc.financialNotify.when.onTimeframe
                    : defaultValues.financialNotify.when.onTimeframe,
                  onOther: doc.financialNotify.when.onOther
                    ? doc.financialNotify.when.onOther
                    : defaultValues.financialNotify.when.onOther,
                }
              : defaultValues.financialNotify.when,
          }
        : defaultValues.financialNotify,
    }
  },
}

const actions = {}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
