import { db, serverTimestamp } from '../../../../plugins/firebase'

const getters = {
  entities_pets: ({ entities }) => {
    return entities.filter((entity) => {
      return entity.type === 'pet'
    })
  },
  entity_pet:
    ({ entities }) =>
    (id = null) => {
      let value = {
        guardian: null,
        maintenance: '$0',
        name: '',
        petType: '',
        type: 'pet',
      }
      if (id) {
        const pets = entities.filter((entity) => {
          return entity.type === 'pet' && entity.id === id
        })
        value = pets[0] || value
      }

      return value
    },
}

const actions = {
  async save_entity_pet({ getters }, { id, pet }) {
    const { uid } = getters.auth_user
    const petId =
      id || db.collection('usersV2').doc(uid).collection('entities').doc().id
    const docRef = db
      .collection('usersV2')
      .doc(uid)
      .collection('entities')
      .doc(petId)

    const data = {
      ...pet,
      type: 'pet',
    }

    if (!id) {
      data.createdAt = serverTimestamp
    }

    return await docRef.set(data, { merge: true })
  },
}

const mutations = {}

export default {
  getters,
  actions,
  mutations,
}
