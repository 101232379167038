<template>
  <v-app>
    <transition
      name="fade"
      mode="out-in"
    >
      <router-view v-if="is_data_loaded"></router-view>
    </transition>
    <transition
      name="fade"
      mode="out-in"
    >
      <v-overlay
        opacity="1"
        color="grey lighten-4"
        v-if="!is_app_loaded"
      >
        <v-img
          :src="require('@/assets/WilledLogo-Inline.svg')"
          position="center center"
          contain
          height="60"
          class="mb-10"
        />
        <v-progress-linear
          color="grey"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-overlay>
    </transition>
    <v-snackbar
      v-model="messageState"
      top
      :color="app_message.type"
      :timeout="app_message.time"
      @input="onSnackChange"
    >
      {{ app_message.text }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  data: () => ({}),
  methods: {
    onSnackChange(val) {
      if (!val) {
        this.$store.state.message = { text: '', type: '' }
      }
    },
  },
  computed: {
    ...mapGetters('app', ['is_app_loaded', 'is_data_loaded', 'app_message']),
    messageState: {
      get() {
        return !!this.app_message.text
      },
      set(val) {
        if (!val) {
          this.$store.commit('app/UNSET_MESSAGE')
        }
      },
    },
  },
}
</script>

<style lang="scss">
/** Fonts **/
@font-face {
  font-family: Graphik;
  src: url('./assets/fonts/Graphik-Regular-Web.woff2');
  font-weight: normal;
}
@font-face {
  font-family: Graphik;
  src: url('./assets/fonts/Graphik-Medium-Web.woff2');
  font-weight: 500;
}
@font-face {
  font-family: Graphik;
  src: url('./assets/fonts/Graphik-Medium-Web.woff2');
  font-weight: bold;
}

@font-face {
  font-family: CooperBT;
  src: url('./assets/fonts/CooperBT-Bold.woff2');
  font-weight: bold;
}
@font-face {
  font-family: CooperBT;
  src: url('./assets/fonts/CooperBT-Light.woff2');
  font-weight: normal;
}

a {
  font-weight: bold;
}

input:disabled,
textarea:disabled,
input:disabled::placeholder,
textarea:disabled::placeholder {
  -webkit-text-fill-color: currentcolor; /* 1. sets text fill to current `color` for safari */
  opacity: 1; /* 2. correct opacity on iOS */
}

/** Typography **/

.w-page-title {
  font-size: 56px !important;
  font-weight: 500;
  line-height: 64px !important;
}

.w-title {
  font-size: 36px !important;
  font-weight: 500;
  line-height: 48px !important;
}

.w-subtitle {
  font-size: 22px !important;
  font-weight: 500;
  line-height: 28px !important;
}

.w-subtext {
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px !important;
}

.w-label {
  font-size: 16px !important;
  font-weight: 500;
  line-height: 24px !important;
}

.no-result {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px !important;
}

@media only screen and (max-width: 599px) {
  .w-page-title {
    font-size: 30px !important;
    font-weight: 500;
    line-height: 36px !important;
  }

  .w-title {
    font-size: 22px !important;
    font-weight: 500;
    line-height: 28px !important;
  }

  .w-subtitle {
    font-size: 20px !important;
    font-weight: 500;
    line-height: 28px !important;
  }

  .w-label {
    font-size: 16px !important;
    font-weight: 500;
    line-height: 24px !important;
  }

  .large-button {
    width: 100%;
  }

  .outline-button {
    width: 100%;

    &.auto {
      width: auto;
    }
  }
}

.wDisabled {
  .w-title,
  .w-subtitle,
  .w-label {
    color: rgba(0, 0, 0, 0.7);
  }
}

a {
  text-decoration: none;
}

.field-wrap {
  margin-bottom: 34px;

  @media only screen and (max-width: 599px) {
    margin-bottom: 2px;
  }
}

.v-btn__content {
  top: -1px;
}

.dense {
  .w-subtitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
}

.dialog-form,
.v-navigation-drawer {
  .actions {
    @media only screen and (max-width: 599px) {
      flex-direction: column;
      .large-button {
        margin-bottom: 24px;
      }
    }
  }
}

.v-dialog__content {
  /* @media only screen and (max-width: 520px) {
      align-items: flex-end !important;
    }*/
  .v-dialog {
    margin: 0px;
  }
}

.v-window {
  overflow: initial !important;
}

/** **/

.theme--light.v-input input::placeholder,
.theme--light.v-input textarea::placeholder {
  color: rgba(0, 0, 0, 0.3) !important;
}

.container {
  &.w-container-wide {
    max-width: 1100px;
    @media only screen and (max-width: 959px) {
      max-width: 620px;
    }
  }
  &.w-container-narrow {
    max-width: 620px;
  }
}

.v-app-bar {
  .v-toolbar__content {
    max-width: 1100px;
    margin: auto;
  }
}

.v-toolbar {
  .container {
    padding: 0;
  }
}

.v-input__slot {
  margin-bottom: 2px !important;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.5s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.v-application {
  .v-card--link:focus:before {
    opacity: 0 !important;
  }
  .px-16 {
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .py-16 {
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .pa-16 {
    padding-left: 64px !important;
    padding-right: 64px !important;
    padding-top: 64px !important;
    padding-bottom: 64px !important;
  }
  .v-divider {
    border-width: 2px 0 0 0;
  }

  .w-width-medium {
    max-width: 320px;
  }

  .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(
      .v-btn--outlined
    ) {
    background-color: #c1c6d0 !important;
    color: #ffffff !important;
  }
}

#MazPhoneNumberInput .input-tel input.input-tel__input {
  border: 2px solid #d6dce7;
  box-shadow: none !important;
  height: 64px;
  font-size: 16px;

  &:hover {
    border-color: #000 !important;
  }
}

.max-w-sm {
  max-width: 525px;
  margin: auto;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.7s ease-in-out;
}
.component-fade-enter, .component-fade-leave-to
    /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.component-fade-move {
  transition: transform 1s;
}

.beacon-padding {
  padding-bottom: 90px;
}

/** Version 2 **/

:root {
  --primary: #b682ff;
  --primary-10: #f4ebff;
  --primary-rgb: 182, 130, 255;
  --sand: #f2eadf;
  --grey: #d6dce7;
  --grey-light: #e6eaf1;
  --grey-0: #f8f8f8;
  --grey-10: #f2f2f2;
  --grey-20: #eeeeee;
  --grey-30: #e7e7e7;
  --grey-40: #e0e0e0;
  --grey-50: #dddddd;
  --success: #008656;
  --orange: #ff7f0b;
  --orange-dark: #c86100;
}

ul li:not(:last-of-type) {
  margin-bottom: 4px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1;
}
.bg- {
  &white {
    background-color: #ffffff !important;
  }
  &black {
    background-color: #000000 !important;
  }
  &primary {
    background-color: var(--primary) !important;
  }
  &primary-10 {
    background-color: var(--primary-10) !important;
  }
  &sand {
    background-color: var(--sand) !important;
  }
  &grey {
    background-color: var(--grey) !important;
  }
  &grey-0 {
    background-color: var(--grey-0) !important;
  }
  &grey-10 {
    background-color: var(--grey-10) !important;
  }
  &grey-20 {
    background-color: var(--grey-20) !important;
  }
  &grey-30 {
    background-color: var(--grey-30) !important;
  }
  &grey-40 {
    background-color: var(--grey-40) !important;
  }
  &grey-50 {
    background-color: var(--grey-50) !important;
  }
}

.theme--light.v-application {
  background-color: #ffffff !important;
  line-height: 1.25;
}

#app-layout {
  background: #ffffff;
}

.bg-sand-white {
  background: linear-gradient(to bottom, var(--sand) 140px, white 0%);
}

#app-footer {
  background-color: var(--sand);
  padding: 32px 0px 100px;
}

#app-illustration {
  max-width: 400px;
  width: 100%;
  height: auto;
  display: block;
  margin: 0px auto;
}

.inner-container {
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 16px;
}

.inner-container-narrow {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 16px;
}

@media only screen and (max-width: 599px) {
  .collapse {
    padding: 0px;
  }
}

.content-box {
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .inner {
    padding: 16px;
  }
}

.divider {
  height: 1px;
  background-color: #d6dce7;
  &-light {
    height: 1px;
    background-color: var(--grey-light);
  }
}
.chat-buffer {
  height: 100px;
}

.clickable:hover {
  background-color: rgba(0, 0, 0, 0.025);
  cursor: pointer;
}

.info-box {
  background-color: rgba(242, 234, 223, 0.4);
  border-radius: 4px;
  padding: 12px;
}

.font- {
  &cooper {
    font-family: CooperBT;
  }
}

.text- {
  &xs {
    font-size: 0.75rem !important;
  }
  &sm {
    font-size: 0.9rem !important;
  }
  &lg {
    font-size: 1.25rem !important;
  }
  &xl {
    font-size: 1.5rem !important;
  }
  &2xl {
    font-size: 1.75rem !important;
  }
  &3xl {
    font-size: 2rem !important;
  }
  &footnote {
    font-size: 13px;
    font-style: italic;
    color: rgba(0, 0, 0, 0.6);
  }
  &cutoff {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &label {
    font-weight: bold;
    margin-bottom: 0.5rem !important;
  }
  &no-result {
    color: rgba(0, 0, 0, 0.75);
    font-style: italic;
    text-align: center;
    padding: 1rem 0;
  }
  &white {
    color: white !important;
  }
}

.results-empty {
  text-align: center;
  padding: 16px;
  opacity: 0.75;
  font-style: italic;
}

@media only screen and (max-width: 400px) {
  .text- {
    &lg {
      font-size: 1.12rem !important;
    }
    &xl {
      font-size: 1.35rem !important;
    }
    &2xl {
      font-size: 1.57rem !important;
    }
    &3xl {
      font-size: 1.8rem !important;
    }
  }
}

.opacity- {
  &100 {
    opacity: 1;
  }
  &90 {
    opacity: 0.9;
  }
  &80 {
    opacity: 0.8;
  }
  &70 {
    opacity: 0.7;
  }
  &60 {
    opacity: 0.6;
  }
  &50 {
    opacity: 0.5;
  }
  &40 {
    opacity: 0.4;
  }
  &30 {
    opacity: 0.3;
  }
  &20 {
    opacity: 0.2;
  }
  &10 {
    opacity: 0.1;
  }
  &0 {
    opacity: 0;
  }
}

.radius- {
  &0 {
    border-radius: 0 !important;
  }
  &2 {
    border-radius: 2px !important;
  }
  &4 {
    border-radius: 4px !important;
  }
  &8 {
    border-radius: 8px !important;
  }
  &12 {
    border-radius: 12px !important;
  }
}

.line-height- {
  &none {
    line-height: 1 !important;
  }
  &xs {
    line-height: 1.1 !important;
  }
  &sm {
    line-height: 1.2 !important;
  }
  &md {
    line-height: 1.25 !important;
  }
}

.border- {
  &0 {
    border: none !important;
  }
  &1 {
    border: 1px solid rgba(0, 0, 0, 0.6) !important;
  }
  &2 {
    border: 2px solid rgba(0, 0, 0, 0.6) !important;
  }
  &divider {
    border-bottom: 1px solid #d6dce7 !important;
  }
  &divider-light {
    border-bottom: 1px solid var(--grey-light) !important;
  }
}

.shadow- {
  &2 {
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  &4 {
    box-shadow:
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12),
      0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
  &8 {
    box-shadow:
      0 5px 5px -3px rgb(0 0 0 / 20%),
      0 8px 10px 1px rgb(0 0 0 / 14%),
      0 3px 14px 2px rgb(0 0 0 / 12%);
  }
}

.text- {
  &white {
    color: #ffffff !important;
  }
  &black {
    color: #ffffff !important;
  }
  &primary {
    color: var(--primary) !important;
  }
  &sand {
    color: var(--sand) !important;
  }
}
.line-through {
  text-decoration: line-through;
}
.uppercase {
  text-transform: uppercase !important;
}

.section {
  margin-bottom: 20px;
}

.section-avatar {
  background-color: rgba(var(--primary-rgb), 0.15);
  border-radius: 8px !important;
}
/** Overrides **/
.theme--light.v-navigation-drawer:not(.v-navigation-drawer--floating)
  .v-navigation-drawer__border {
  display: none !important;
}

.v-messages {
  font-size: 15px !important;
  min-height: 17px !important;
  .v-messages__wrapper .v-messages__message {
    line-height: 17px;
  }
}

.v-expansion-panel-header:before {
  background-color: var(--primary) !important;
}

.v-expansion-panel--active {
  border-left: 4px solid var(--primary);
}

.theme--light.v-expansion-panels.v-expansion-panels--focusable
  .v-expansion-panel-header:hover::before {
  opacity: 0.15 !important;
}
.theme--light.v-expansion-panels.v-expansion-panels--focusable
  .v-expansion-panel-header--active:hover::before,
.theme--light.v-expansion-panels.v-expansion-panels--focusable
  .v-expansion-panel-header--active::before {
  opacity: 0 !important;
}

.v-btn.bg-white:hover:before {
  background-color: var(--primary);
  opacity: 0.15 !important;
}
.v-text-field--filled.small-input > .v-input__control > .v-input__slot,
.v-text-field--full-width.small-input > .v-input__control > .v-input__slot,
.v-text-field--outlined.small-input > .v-input__control > .v-input__slot {
  min-height: 48px;
}
</style>
